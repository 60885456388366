.c-ticket-wrap {
    background: #000000;
    bottom:0;
    left:0;
    overflow-y:scroll;
    position:fixed;
    top:0;
    width:100%;
    z-index:1050;
}

body.theme-platinum .c-ticket-wrap {
    background: vaR(--primary-b);
}

@media (min-width: 768px) {
    .c-ticket-wrap {
        overflow-y: hidden;
    }
}

.c-ticket {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin-bottom: 2.5rem;
    overflow: hidden;
}

.c-ticket__image,
.c-ticket__primary,
.c-ticket__secondary {
    flex-grow: 1;
    max-width: 100%;
    position: relative;
}


.c-ticket__image,
.c-ticket__secondary {
    border-radius: 0.75rem;
    flex-basis: 50%;
}

@media (min-width: 768px) {
    .c-ticket__image,
    .c-ticket__primary,
    .c-ticket__secondary {
        flex-basis: 0;
    }
}

.c-ticket__image {
    background-color: #fff;
    background-size: cover;

    order: 1;
}

.c-ticket__image img {
    display: block;
    width: 100%;
    height: auto;
}

@media (min-width: 768px) {
    .c-ticket__image {
        display: block;
        max-width: 25%;
        order: 1;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
}

.c-ticket__primary {
    color: var(--text-tertiary);
    margin-top: 2rem;
    order: 3;
}
body.theme-platinum .c-ticket__primary { color: var(--text-primary); }

@media (min-width: 768px) {
    .c-ticket__primary {
        margin-top: 0;
        max-width: 50%;
        padding: 2rem 2.5rem;
        background: white;
        color: black;
        border-top-right-radius: .75rem;
        border-bottom-right-radius: .75rem;
        order: 2;
    }
    body.theme-platinum .c-ticket__primary {
        background: var(--text-primary);
        color: black;
    }
}

.c-ticket__secondary {
    text-align: center;
    padding: 1.5rem;
    background-color: #fff;

    order: 2;
    position:relative;
}

.c-ticket__image:before,
.c-ticket__primary:before,
.c-ticket__secondary:before {
    display: block;
    width: 0;
    height: 95%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    border-left: 4px dotted #fff;
}

.c-ticket__image:before,
.c-ticket__primary:before {
    right: -2px;
    margin-top: 2px;
    z-index: 10;
}

.c-ticket__secondary:before {
    left: -2px;
    margin-top: -2px;
    border-color: #000 !important;
}

body.theme-platinum .c-ticket__secondary:before {
    border-color: var(--page-bg) !important;
}

.c-ticket__image:before,
.c-ticket__secondary:before {
    content: '';
}

@media (min-width: 768px) {
    .c-ticket__image:before,
    .c-ticket__primary:before,
    .c-ticket__secondary:before {
        border-left: 6px dotted #fff;
    }
    
    body.theme-platinum .c-ticket__image:before,
    body.theme-platinum .c-ticket__primary:before,
    body.theme-platinum .c-ticket__secondary:before {
        border-left-color: var(--text-primary);
    }

    .c-ticket__image:before,
    .c-ticket__primary:before {
        right: -3px;
        margin-top: 3px;
    }

    .c-ticket__secondary:before {
        left: -3px;
        margin-top: -3px;
    }

    .c-ticket__image:before {
        content: none;
    }

    .c-ticket__primary:before,
    .c-ticket__secondary:before {
        content: '';
    }

    .c-ticket__secondary {
        max-width:25%;
        order: 3;
        padding: 2rem;
    }
}