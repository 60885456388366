.cinemaprende-class {
    border: 1px solid #d9dde0;
    margin-bottom: 0.25em;
}
.cinemaprende-class-title,
.cinemaprende-class-content {
    padding: 1em 1.5em;
}

.cinemaprende-class-title {
    cursor: pointer;
    font-size: 1.25rem;
    margin: 0 !important;
    position: relative;
}
.cinemaprende-class-title:after {
    content: "+";
    position: absolute;
    top: 0.5em;
    right: 0.75em;
    font-size: 1.5em;
}
.cinemaprende-class-content {
    display: none;
}
.cinemaprende-class.open .cinemaprende-class-content {
    display: block;
}
.cinemaprende-class.open .cinemaprende-class-title:after {
    content: "-";
}