:root {
  --bg-primary: #FFFFFF;
  --bg-secondary: #F0F0F0;

  --primary: #FE142F;
  --accent: #770118;

  --text-primary: #000000;
  --text-tertiary: #FFFFFF;
  --page-bg: var(--bg-primary);

  --ff-text: 'Maison Neue', sans-serif;
  --ff-title: 'VVDS Fifties Cond Med', sans-serif;
  --ff-subtitle: 'VVDS Fifties SemiExp', sans-serif;

  /* --fs-text: 12px; */

  --color-status-safe: #00D975;
  --color-status-mid: #EFB930;
  --color-status-danger: var(--primary);

  --color-secondary-purple: #890B50;
  --color-secondary-yellow: #EFB930;

  --pad-x: 1.5rem;
  --pad-y: 1.5rem;

  @media screen and (min-width: 768px) {
    --pad-x: 30px;
    --pad-y: 30px;
  }
}

body.theme-market {
  --primary: #FE3B1F;
  --page-bg: #000000;
  --text-primary: #FFFFFF;
  --disabled-text: #AAAAAA;
  --disabled-btn: #4D4D4d;

  --ff-text: 'Basis Grotesque Mono', sans-serif;
  --ff-title: 'Plain', sans-serif;
  --ff-subtitle: 'Plain', sans-serif;
  
  --fs-text: 10px;
}

@media screen and (min-width: 768px) {
  body.theme-market { --fs-text: 14px; }
}

body.theme-platinum {
  --bg-secondary: #D9D8D6;

  --primary: #EE2C37;
  --primary-b: #731013;
  --primary-c: #941114;
  --page-bg: var(--primary-b);
  --text-primary: #D9D8D6;

  --primary-b-hr: #a54245;

  --ff-text: 'PP Neue Montreal', sans-serif;
  --ff-title: var(--ff-text);
  --ff-subtitle: var(--ff-text);
}

body {
  background-color: var(--page-bg);
  color: var(--text-primary);
  font-family: var(--ff-text);
  font-size: var(--fs-text);
}

.pad-x {
  padding-left: var(--pad-x);
  padding-right: var(--pad-x);
}

.pad-y {
  padding-bottom: var(--pad-x);
  padding-top: var(--pad-x);
}

.pad-xy {
  padding-bottom: var(--pad-x);
  padding-left: var(--pad-x);
  padding-right: var(--pad-x);
  padding-top: var(--pad-x);
}

a { color: var(--primary); }
a:hover { color: var(--primary); }

.title-1,
.title-2 {
  --local-fw: 400;
  font-family: var(--ff-title);
  font-weight: var(--local-fw);
  line-height: 1;
  text-transform: uppercase;
}

.title-1 {
  font-size: 50px;
}

.title-2 {
  font-size: 20px;
}

.subtitle-1 {
  font-size: 18px;
  font-weight: 700;
}

.subtitle-2 {
  font-size: 1rem;
  font-weight: 700;
}

body.theme-market .title-1,
body.theme-market .title-2 {
  text-transform: none;
}

body.theme-market .title-1 {
  font-size: 30px;
  --local-fw: 700;
}

body.theme-market .title-2 {
  --local-fw: 900;
}

body.theme-platinum .title-1,
body.theme-platinum .title-2 {
  color: var(--primary);
  font-weight: 700;
  font-size: 2.1875rem;
}

.c-card,
.c-card__image { border-radius: 0; }


@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.fade-in {
  animation: fadeIn 0.5s ease-out 0s 1;
}

@keyframes fadeOut {
  0% { opacity: 1; }
  100% { opacity: 0; }
}

.fade-out {
  animation: fadeOut 0.5s ease-out 0s 1;
}

.fade-opacity-in {
  opacity: 1;
  transition: visibility 0s linear 0s, opacity 0.5s;
  visibility: visible;
}

.fade-opacity-out {
  opacity: 0;
  transition: visibility 0s linear 0.5s, opacity 0.5s;
  visibility: hidden;
}

@keyframes slideInFromRight {
  0% { transform: translateX(100%); }
  100% { transform: translateX(0); }
}

.slide-in-from-right {
  animation: slideInFromRight 0.5s ease-out 0s 1;
}

@keyframes slideInFromLeft {
  0% { transform: translateX(-100%); }
  100% { transform: translateX(0); }
}

.slide-in-from-left {
  animation: slideInFromLeft 0.5s ease-out 0s 1;
}

.checkout-disclaimer {
  font-size: 0.75em;
  line-height: 1.2em;
  opacity: 0.75;
}


.form-control{
  display:block;
  width:100%;
  padding:.375rem 0;
  font-size:1rem;
  line-height:1.5;
  color:var(--text-primary);
  background-color:var(--page-bg) !important;
  background-clip:padding-box;
  border:1px solid var(--text-primary);
  border-radius:0;
  -webkit-box-shadow:none;
  box-shadow:none;
  transition:border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce){.form-control{-webkit-transition:none;transition:none}}

.form-control::-ms-expand{background-color:transparent;border:0}
.form-control--focus,
.form-control:focus{color:var(--text-primary);background-color:var(--page-bg);border-color:var(--primary);outline:0;-webkit-box-shadow:none,0 5px 20px 0 rgba(0,0,0,0.15);box-shadow:none,0 5px 20px 0 rgba(0,0,0,0.15)}
.form-control::-webkit-input-placeholder{color:#6c757d;opacity:1}
.form-control:-ms-input-placeholder{color:#6c757d;opacity:1}
.form-control::-ms-input-placeholder{color:#6c757d;opacity:1}
.form-control::placeholder{color:#6c757d;opacity:1}
body.theme-platinum .form-control::placeholder{color:var(--text-primary)}
.form-control:disabled,.form-control[readonly]{background-color:#e6e6e6;opacity:1}
select.form-control:not([size]):not([multiple]){height:calc(2.25rem + 4px)}
select.form-control:focus::-ms-value{color:#495057;background-color:#fff}

.form-group.form-group--label-helper{position:relative;padding-top:27px}
.form-group.form-group--label-helper label{
  color: var(--text-primary);
  font-size: 0.87rem;
  font-weight: 700;
  opacity:0;position:absolute;top:30px;left:0;-webkit-transition:all .3s ease;transition:all .3s ease;
}
body.theme-platinum .form-group.form-group--label-helper label{
  text-transform: uppercase;
}
.form-group.form-group--label-helper.not-empty label,
.form-group.form-group--label-helper .form-control:focus+label{opacity:1;top:10px}
.form-group label{
  line-height:1;
  color: var(--text-primary);
  font-size: 0.87rem;
  font-weight: 700;
}

.l-wrapper__sidebar-toggle {
  background-color: var(--page-bg);
}

.bg-primary {
  background-color: var(--primary) !important;
}
.c-entry__body h1, .c-entry__body h2, .c-entry__body h3, .c-entry__body h4, .c-entry__body h5, .c-entry__body h6 {
  color: var(--primary);
}

body.theme-platinum .input-range__track--background {
  background-color: var(--text-primary);
}

body.theme-platinum .text-secondary { color: var(--text-primary) !important; }
body.theme-platinum .text-white { color: var(--text-primary) !important; }
body.theme-platinum .border-bottom { border-color: var(--primary-b-hr) !important; }
body.theme-platinum .c-inline-modal__modal,
body.theme-platinum .c-inline-modal__modal-close { background-color: var(--primary-b); }
body.theme-platinum .c-inline-modal__modal-close { color: var(--text-primary); }
body.theme-platinum .btn-outline-white,
body.theme-platinum #contact-page-sidebar .btn-outline-secondary { border-color: var(--text-primary); color: var(--text-primary); }
body.theme-platinum .btn-outline-white:hover,
body.theme-platinum #contact-page-sidebar .btn-outline-secondary:hover { background-color: var(--text-primary); color: var(--primary-b); }
body.theme-platinum .was-validated .form-message-invalid { color: var(--primary); }
