.input-range__slider {
    background-color: var(--primary);
    border-color: var(--primary);
}
.input-range__track--active {
    background-color: var(--primary);
}
.input-range__label-container {
    background: var(--page-bg);
    bottom: -3.5em;
    color: var(--text-color);
    font-family: sans-serif;
}