
body.theme-platinum #footer .border-secondary { border-color: var(--primary-b-hr) !important; }
.input-range__slider {
    background-color: var(--primary);
    border-color: var(--primary);
}
.input-range__track--active {
    background-color: var(--primary);
}
.input-range__label-container {
    background: var(--page-bg);
    bottom: -3.5em;
    color: var(--text-color);
    font-family: sans-serif;
}
.c-seats {
    display: flex;
    justify-content: center;
}

.c-seats--indicators{
    justify-content: space-between;
    flex-wrap: wrap;

    @media screen and (min-width: 768px) {
        justify-content: space-around;
    }
}

.c-seats--indicators .c-seats__seat{
    cursor: default !important;
    white-space: nowrap;
}

.c-seats__row{
    display: flex;
}

.c-seats__seat{
    cursor: pointer;
    font-size: 14px;
}

@media (min-width: 768px){
    .c-seats__seat{
        font-size: 16px;
        padding: .15rem;
    }
}

.c-seats__seat.is-hide{
    cursor: default;
    visibility: hidden;
}

.c-seats__seat.is-separator{
    cursor: default;
    position: relative;
}

.c-seats__seat.is-separator i{
    visibility: hidden;
}

.c-seats__seat.is-separator:before{
    content:'';
    display: block;
    height: 2px;
    left: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
}

.c-seats__seat.is-occupied{
    cursor: not-allowed;
}
.c-ticket-wrap {
    background: #000000;
    bottom:0;
    left:0;
    overflow-y:scroll;
    position:fixed;
    top:0;
    width:100%;
    z-index:1050;
}

body.theme-platinum .c-ticket-wrap {
    background: vaR(--primary-b);
}

@media (min-width: 768px) {
    .c-ticket-wrap {
        overflow-y: hidden;
    }
}

.c-ticket {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin-bottom: 2.5rem;
    overflow: hidden;
}

.c-ticket__image,
.c-ticket__primary,
.c-ticket__secondary {
    flex-grow: 1;
    max-width: 100%;
    position: relative;
}


.c-ticket__image,
.c-ticket__secondary {
    border-radius: 0.75rem;
    flex-basis: 50%;
}

@media (min-width: 768px) {
    .c-ticket__image,
    .c-ticket__primary,
    .c-ticket__secondary {
        flex-basis: 0;
    }
}

.c-ticket__image {
    background-color: #fff;
    background-size: cover;

    order: 1;
}

.c-ticket__image img {
    display: block;
    width: 100%;
    height: auto;
}

@media (min-width: 768px) {
    .c-ticket__image {
        display: block;
        max-width: 25%;
        order: 1;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
}

.c-ticket__primary {
    color: var(--text-tertiary);
    margin-top: 2rem;
    order: 3;
}
body.theme-platinum .c-ticket__primary { color: var(--text-primary); }

@media (min-width: 768px) {
    .c-ticket__primary {
        margin-top: 0;
        max-width: 50%;
        padding: 2rem 2.5rem;
        background: white;
        color: black;
        border-top-right-radius: .75rem;
        border-bottom-right-radius: .75rem;
        order: 2;
    }
    body.theme-platinum .c-ticket__primary {
        background: var(--text-primary);
        color: black;
    }
}

.c-ticket__secondary {
    text-align: center;
    padding: 1.5rem;
    background-color: #fff;

    order: 2;
    position:relative;
}

.c-ticket__image:before,
.c-ticket__primary:before,
.c-ticket__secondary:before {
    display: block;
    width: 0;
    height: 95%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    border-left: 4px dotted #fff;
}

.c-ticket__image:before,
.c-ticket__primary:before {
    right: -2px;
    margin-top: 2px;
    z-index: 10;
}

.c-ticket__secondary:before {
    left: -2px;
    margin-top: -2px;
    border-color: #000 !important;
}

body.theme-platinum .c-ticket__secondary:before {
    border-color: var(--page-bg) !important;
}

.c-ticket__image:before,
.c-ticket__secondary:before {
    content: '';
}

@media (min-width: 768px) {
    .c-ticket__image:before,
    .c-ticket__primary:before,
    .c-ticket__secondary:before {
        border-left: 6px dotted #fff;
    }
    
    body.theme-platinum .c-ticket__image:before,
    body.theme-platinum .c-ticket__primary:before,
    body.theme-platinum .c-ticket__secondary:before {
        border-left-color: var(--text-primary);
    }

    .c-ticket__image:before,
    .c-ticket__primary:before {
        right: -3px;
        margin-top: 3px;
    }

    .c-ticket__secondary:before {
        left: -3px;
        margin-top: -3px;
    }

    .c-ticket__image:before {
        content: none;
    }

    .c-ticket__primary:before,
    .c-ticket__secondary:before {
        content: '';
    }

    .c-ticket__secondary {
        max-width:25%;
        order: 3;
        padding: 2rem;
    }
}
.bubbles-container {
    width: 100%;
    height: 100vh;
    position: fixed;
    overflow: hidden;
    top: 0;
    left: 0;
    pointer-events: none;
    z-index: 999999;
}

.bub {
    width: 100px;
    height: 100px;
    border: 1px solid white;
    box-shadow: inset 5px -5px 10px white;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, .01);
    -webkit-backdrop-filter: blur(2px);
            backdrop-filter: blur(2px);
    position: absolute;
    bottom: -120px;
    /* animation: bubble 3s ease-in infinite; */
}

.bub::before {
    position: absolute;
    content: "";
    background-color: white;
    width: 20%;
    height: 20%;
    border-radius: 50%;
    top: 25%;
    right: 23%;
    box-shadow: 0px 0px 20px white;
}

.bub.a {
    left: 10%;
    animation: bubble2 2s ease-in 1s 2;
}

.bub.b {
    left: 20%;
    animation: bubble 1.5s ease-in 1.4s 1;
}

.bub.c {
    left: 28%;
    animation: bubble 5s ease-in 3.8s 1;
}

.bub.d {
    left: 40%;
    animation: bubble 1.8s ease-in .5s 3;
}

.bub.e {
    left: 75%;
    animation: bubble2 2.3s ease-in 2.5s 3;
}

.bub.f {
    left: 90%;
    animation: bubble 2.5s ease-in 3s 2;
}

.bub.g {
    left: 60%;
    animation: bubble 2.2s ease-in 2s 1;
}

.bub.k {
    left: 50%;
    animation: bubble 1.6s ease-in 2s 3;
}

.bub.i {
    left: 65%;
    animation: bubble2 1.8s ease-in 2.1s 2;
}

.bub.j {
    left: 3%;
    animation: bubble 2s ease-in 1.5s 3;
}

.bub.h {
    left: 35%;
    animation: bubble2 3s ease-in 3;
}

@keyframes bubble {
    0% {
        opacity: 0;
    }
    10%,
    93% {
        opacity: 1;
    }
    100% {
        transform: translate(90px, -700px);
        display: none;
    }
}

@keyframes bubble2 {
    0% {
        opacity: 0;
    }
    10%,
    93% {
        opacity: 1;
    }
    100% {
        transform: translate(-90px, -700px);
        display: none;
    }
}
.cinemaprende-class {
    border: 1px solid #d9dde0;
    margin-bottom: 0.25em;
}
.cinemaprende-class-title,
.cinemaprende-class-content {
    padding: 1em 1.5em;
}

.cinemaprende-class-title {
    cursor: pointer;
    font-size: 1.25rem;
    margin: 0 !important;
    position: relative;
}
.cinemaprende-class-title:after {
    content: "+";
    position: absolute;
    top: 0.5em;
    right: 0.75em;
    font-size: 1.5em;
}
.cinemaprende-class-content {
    display: none;
}
.cinemaprende-class.open .cinemaprende-class-content {
    display: block;
}
.cinemaprende-class.open .cinemaprende-class-title:after {
    content: "-";
}
:root {
  --bg-primary: #FFFFFF;
  --bg-secondary: #F0F0F0;

  --primary: #FE142F;
  --accent: #770118;

  --text-primary: #000000;
  --text-tertiary: #FFFFFF;
  --page-bg: var(--bg-primary);

  --ff-text: 'Maison Neue', sans-serif;
  --ff-title: 'VVDS Fifties Cond Med', sans-serif;
  --ff-subtitle: 'VVDS Fifties SemiExp', sans-serif;

  /* --fs-text: 12px; */

  --color-status-safe: #00D975;
  --color-status-mid: #EFB930;
  --color-status-danger: var(--primary);

  --color-secondary-purple: #890B50;
  --color-secondary-yellow: #EFB930;

  --pad-x: 1.5rem;
  --pad-y: 1.5rem;

  @media screen and (min-width: 768px) {
    --pad-x: 30px;
    --pad-y: 30px;
  }
}

body.theme-market {
  --primary: #FE3B1F;
  --page-bg: #000000;
  --text-primary: #FFFFFF;
  --disabled-text: #AAAAAA;
  --disabled-btn: #4D4D4d;

  --ff-text: 'Basis Grotesque Mono', sans-serif;
  --ff-title: 'Plain', sans-serif;
  --ff-subtitle: 'Plain', sans-serif;
  
  --fs-text: 10px;
}

@media screen and (min-width: 768px) {
  body.theme-market { --fs-text: 14px; }
}

body.theme-platinum {
  --bg-secondary: #D9D8D6;

  --primary: #EE2C37;
  --primary-b: #731013;
  --primary-c: #941114;
  --page-bg: var(--primary-b);
  --text-primary: #D9D8D6;

  --primary-b-hr: #a54245;

  --ff-text: 'PP Neue Montreal', sans-serif;
  --ff-title: var(--ff-text);
  --ff-subtitle: var(--ff-text);
}

body {
  background-color: var(--page-bg);
  color: var(--text-primary);
  font-family: var(--ff-text);
  font-size: var(--fs-text);
}

.pad-x {
  padding-left: var(--pad-x);
  padding-right: var(--pad-x);
}

.pad-y {
  padding-bottom: var(--pad-x);
  padding-top: var(--pad-x);
}

.pad-xy {
  padding-bottom: var(--pad-x);
  padding-left: var(--pad-x);
  padding-right: var(--pad-x);
  padding-top: var(--pad-x);
}

a { color: var(--primary); }
a:hover { color: var(--primary); }

.title-1,
.title-2 {
  --local-fw: 400;
  font-family: var(--ff-title);
  font-weight: var(--local-fw);
  line-height: 1;
  text-transform: uppercase;
}

.title-1 {
  font-size: 50px;
}

.title-2 {
  font-size: 20px;
}

.subtitle-1 {
  font-size: 18px;
  font-weight: 700;
}

.subtitle-2 {
  font-size: 1rem;
  font-weight: 700;
}

body.theme-market .title-1,
body.theme-market .title-2 {
  text-transform: none;
}

body.theme-market .title-1 {
  font-size: 30px;
  --local-fw: 700;
}

body.theme-market .title-2 {
  --local-fw: 900;
}

body.theme-platinum .title-1,
body.theme-platinum .title-2 {
  color: var(--primary);
  font-weight: 700;
  font-size: 2.1875rem;
}

.c-card,
.c-card__image { border-radius: 0; }


@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.fade-in {
  animation: fadeIn 0.5s ease-out 0s 1;
}

@keyframes fadeOut {
  0% { opacity: 1; }
  100% { opacity: 0; }
}

.fade-out {
  animation: fadeOut 0.5s ease-out 0s 1;
}

.fade-opacity-in {
  opacity: 1;
  transition: visibility 0s linear 0s, opacity 0.5s;
  visibility: visible;
}

.fade-opacity-out {
  opacity: 0;
  transition: visibility 0s linear 0.5s, opacity 0.5s;
  visibility: hidden;
}

@keyframes slideInFromRight {
  0% { transform: translateX(100%); }
  100% { transform: translateX(0); }
}

.slide-in-from-right {
  animation: slideInFromRight 0.5s ease-out 0s 1;
}

@keyframes slideInFromLeft {
  0% { transform: translateX(-100%); }
  100% { transform: translateX(0); }
}

.slide-in-from-left {
  animation: slideInFromLeft 0.5s ease-out 0s 1;
}

.checkout-disclaimer {
  font-size: 0.75em;
  line-height: 1.2em;
  opacity: 0.75;
}


.form-control{
  display:block;
  width:100%;
  padding:.375rem 0;
  font-size:1rem;
  line-height:1.5;
  color:var(--text-primary);
  background-color:var(--page-bg) !important;
  background-clip:padding-box;
  border:1px solid var(--text-primary);
  border-radius:0;
  box-shadow:none;
  transition:border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce){.form-control{transition:none}}

.form-control::-ms-expand{background-color:transparent;border:0}
.form-control--focus,
.form-control:focus{color:var(--text-primary);background-color:var(--page-bg);border-color:var(--primary);outline:0;box-shadow:none,0 5px 20px 0 rgba(0,0,0,0.15)}
.form-control::placeholder{color:#6c757d;opacity:1}
body.theme-platinum .form-control::placeholder{color:var(--text-primary)}
.form-control:disabled,.form-control[readonly]{background-color:#e6e6e6;opacity:1}
select.form-control:not([size]):not([multiple]){height:calc(2.25rem + 4px)}
select.form-control:focus::-ms-value{color:#495057;background-color:#fff}

.form-group.form-group--label-helper{position:relative;padding-top:27px}
.form-group.form-group--label-helper label{
  color: var(--text-primary);
  font-size: 0.87rem;
  font-weight: 700;
  opacity:0;position:absolute;top:30px;left:0;transition:all .3s ease;
}
body.theme-platinum .form-group.form-group--label-helper label{
  text-transform: uppercase;
}
.form-group.form-group--label-helper.not-empty label,
.form-group.form-group--label-helper .form-control:focus+label{opacity:1;top:10px}
.form-group label{
  line-height:1;
  color: var(--text-primary);
  font-size: 0.87rem;
  font-weight: 700;
}

.l-wrapper__sidebar-toggle {
  background-color: var(--page-bg);
}

.bg-primary {
  background-color: var(--primary) !important;
}
.c-entry__body h1, .c-entry__body h2, .c-entry__body h3, .c-entry__body h4, .c-entry__body h5, .c-entry__body h6 {
  color: var(--primary);
}

body.theme-platinum .input-range__track--background {
  background-color: var(--text-primary);
}

body.theme-platinum .text-secondary { color: var(--text-primary) !important; }
body.theme-platinum .text-white { color: var(--text-primary) !important; }
body.theme-platinum .border-bottom { border-color: var(--primary-b-hr) !important; }
body.theme-platinum .c-inline-modal__modal,
body.theme-platinum .c-inline-modal__modal-close { background-color: var(--primary-b); }
body.theme-platinum .c-inline-modal__modal-close { color: var(--text-primary); }
body.theme-platinum .btn-outline-white,
body.theme-platinum #contact-page-sidebar .btn-outline-secondary { border-color: var(--text-primary); color: var(--text-primary); }
body.theme-platinum .btn-outline-white:hover,
body.theme-platinum #contact-page-sidebar .btn-outline-secondary:hover { background-color: var(--text-primary); color: var(--primary-b); }
body.theme-platinum .was-validated .form-message-invalid { color: var(--primary); }

