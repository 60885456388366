.c-seats {
    display: flex;
    justify-content: center;
}

.c-seats--indicators{
    justify-content: space-between;
    flex-wrap: wrap;

    @media screen and (min-width: 768px) {
        justify-content: space-around;
    }
}

.c-seats--indicators .c-seats__seat{
    cursor: default !important;
    white-space: nowrap;
}

.c-seats__row{
    display: flex;
}

.c-seats__seat{
    cursor: pointer;
    font-size: 14px;
}

@media (min-width: 768px){
    .c-seats__seat{
        font-size: 16px;
        padding: .15rem;
    }
}

.c-seats__seat.is-hide{
    cursor: default;
    visibility: hidden;
}

.c-seats__seat.is-separator{
    cursor: default;
    position: relative;
}

.c-seats__seat.is-separator i{
    visibility: hidden;
}

.c-seats__seat.is-separator:before{
    content:'';
    display: block;
    height: 2px;
    left: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
}

.c-seats__seat.is-occupied{
    cursor: not-allowed;
}