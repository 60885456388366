.bubbles-container {
    width: 100%;
    height: 100vh;
    position: fixed;
    overflow: hidden;
    top: 0;
    left: 0;
    pointer-events: none;
    z-index: 999999;
}

.bub {
    width: 100px;
    height: 100px;
    border: 1px solid white;
    box-shadow: inset 5px -5px 10px white;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, .01);
    backdrop-filter: blur(2px);
    position: absolute;
    bottom: -120px;
    /* animation: bubble 3s ease-in infinite; */
}

.bub::before {
    position: absolute;
    content: "";
    background-color: white;
    width: 20%;
    height: 20%;
    border-radius: 50%;
    top: 25%;
    right: 23%;
    box-shadow: 0px 0px 20px white;
}

.bub.a {
    left: 10%;
    animation: bubble2 2s ease-in 1s 2;
}

.bub.b {
    left: 20%;
    animation: bubble 1.5s ease-in 1.4s 1;
}

.bub.c {
    left: 28%;
    animation: bubble 5s ease-in 3.8s 1;
}

.bub.d {
    left: 40%;
    animation: bubble 1.8s ease-in .5s 3;
}

.bub.e {
    left: 75%;
    animation: bubble2 2.3s ease-in 2.5s 3;
}

.bub.f {
    left: 90%;
    animation: bubble 2.5s ease-in 3s 2;
}

.bub.g {
    left: 60%;
    animation: bubble 2.2s ease-in 2s 1;
}

.bub.k {
    left: 50%;
    animation: bubble 1.6s ease-in 2s 3;
}

.bub.i {
    left: 65%;
    animation: bubble2 1.8s ease-in 2.1s 2;
}

.bub.j {
    left: 3%;
    animation: bubble 2s ease-in 1.5s 3;
}

.bub.h {
    left: 35%;
    animation: bubble2 3s ease-in 3;
}

@keyframes bubble {
    0% {
        opacity: 0;
    }
    10%,
    93% {
        opacity: 1;
    }
    100% {
        transform: translate(90px, -700px);
        display: none;
    }
}

@keyframes bubble2 {
    0% {
        opacity: 0;
    }
    10%,
    93% {
        opacity: 1;
    }
    100% {
        transform: translate(-90px, -700px);
        display: none;
    }
}